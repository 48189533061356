@import '../../less/variable.less';

.dealngon {
  .dealngon-category {
    .ant-list-item {
      padding: @size-5 0;
    }

    .category-title {
      color: @primary-color;
    }

    .website-item {
      background-color: #f5f5f7;
      padding: 16px 24px;
      border-radius: @size-8;
      margin-bottom: @size-8;

      .ant-row {
        width: 100%;
      }

      .drag-icon {
        svg {
          margin: auto 0;
        }
        cursor: pointer;
      }
    }
    .website-button {
      width: 124px;
      height: 40px;
      border-radius: 8px;
    }

    input {
      padding: @size-8 @size-16;
      border: 1px solid @border-color;
      border-radius: @size-8;
    }

    .category-form-input .ant-select-selector {
      border: 1px solid @border-color !important;
      border-radius: @size-8 !important;
      min-height: 48px !important;
      padding: @size-8 @size-16 !important;
    }
  }

  .dealngon-website {
    input {
      padding: @size-8 @size-16;
      border: 1px solid @border-color;
      border-radius: @size-8;
    }
    .ant-select-selector {
      border: 1px solid @border-color !important;
      border-radius: @size-8 !important;
      min-height: 48px !important;
      padding: @size-8 @size-16 !important;
    }
  }

  .dealngon-banner {
    .banner-button {
      margin-top: @size-8;
      width: 124px;
      height: 40px;
      border-radius: 8px;
    }
    input {
      padding: @size-8 @size-16;
      border: 1px solid @border-color;
      border-radius: @size-8;
    }
  }

  .dealngon-tags {
    .tag-list {
      padding: @size-16;
      border: 1px solid @border-color;
      border-radius: @size-8;
    }

    .tag-item {
      padding: @size-8;
      background-color: #f5f5f7;
      border: none;
      border-radius: @size-8;
      cursor: pointer;
    }

    .tag-button {
      margin-top: @size-8;
      width: 124px;
      height: 40px;
      border-radius: 8px;
    }

    input {
      padding: @size-8 @size-16;
      border: 1px solid @border-color;
      border-radius: @size-8;
    }
  }

  .dealngon-icon {
    .upload-button {
      color: @primary-color;
      border: none !important;
      background-color: #f3f6ff;
    }

    .icon-item {
      height: 102px;
      width: 102px;
      border-radius: 8px;
      border: 1px solid #e1e2e5;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      position: relative;

      .delete-button {
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }
  }
}

.icon-container {
  span.ant-upload-span {
    display: flex;
    justify-content: center;
    align-items: center;
    a.ant-upload-list-item-thumbnail {
      width: 30px;
      height: 30px;
    }
  }
  div.ant-upload-list.ant-upload-list-picture-card {
    display: flex;
    flex-wrap: wrap;
  }
  div.ant-upload-list.ant-upload-list-picture-card > div {
    order: 2;
  }
  div.ant-upload-list.ant-upload-list-picture-card > div:nth-last-child(1) {
    order: 1;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;