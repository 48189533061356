@import '../../less/variable.less';

.log {
  .ant-card-body {
    padding: 0;
  }
}

.message {
  color: @primary-color;
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;