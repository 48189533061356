@import '../../less/variable.less';

#app {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  .page-view {
    width: 100%;
    flex: 1;
    display: inherit;
    .content-view {
      width: 100%;
      flex: 1;
      background-color: @white-color;
      border-radius: @size-4;
    }
  }
}

@media screen and (min-width: @breakpoint-mobile) {
  #app {
    flex-direction: column;
    padding: @size-10;
    .page-view {
      padding: @size-10 0 0;
      .content-view {
        padding: @size-10;
      }
    }
  }
}
@media screen and (min-width: @breakpoint-desktop-small) {
  #app {
    flex-direction: row;
    padding: @size-20;
    .page-view {
      max-width: calc(100% - @size-300);
      padding: 0 @size-10 0 @size-20;
      .content-view {
        padding: @size-20;
      }
    }
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;