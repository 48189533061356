.drawer {
  .card-layout {
    margin-bottom: 30px;
    .ant-card-body {
      > .ant-divider {
        margin: 12px 0;
      }
    }
    &:first-child {
      .ant-card-body {
        display: flex;
      }
    }
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;