@import '../../less/variable.less';

.list-address {
  background-color: @white-color !important;
  padding: @size-15;
  margin-bottom: @size-20;
  border-radius: 5px;

  .btn {
    margin-bottom: 20px;
  }

  .address-item:not(:last-of-type) {
    margin-bottom: @size-10;
  }

  .address-item {
    button {
      width: 100%;
    }
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;