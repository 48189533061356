@import '../../less/variable.less';

.card {
  .card-item {
    width: 100%;
    margin-bottom: 20px;
    .label {
      color: #3260ff;
    }
    .uri {
      width: 100%;
      height: 40px;
      border: 1px solid rgb(66, 66, 66);
    }
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;