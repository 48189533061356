@import '../../less/variable.less';

.autocomplete {
  width: 100%;
}

.customer-detail {
  display: block;
  width: 100%;
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;