@import '../../less/variable.less';

.card {
  // position: absolute;
  // bottom: @size-10;
  // left: @size-15;
  // right: @size-15;
  padding: @size-10;
  .name {
    margin: 0;
  }
  .content {
    padding: @size-15 0;
    .role {
      color: @primary-color;
    }
    .text {
      font-weight: 700;
      width: 100%;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .logout {
    display: inline;
    padding: 0;
    width: fit-content;
    font-weight: 600;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;