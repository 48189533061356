@import '../../less/variable.less';

.fragment-theme {
  position: relative;
  .btn-action {
    position: absolute;
    right: 0;
    top: -98px;
    display: flex;
    min-width: 310px;
    align-items: center;
    justify-content: space-between;
    .ant-btn {
      border-radius: 8px;
      padding: 8px 20px;
      min-height: 40px;
      width: 150px;
      &:first-child,
      &:last-child {
        border-radius: 8px !important;
      }
      &:hover {
        border-color: transparent;
      }
      &.save {
        background-color: @secondary-color;
        color: @white-color;
      }
    }
  }
  .card {
    width: 100%;
    margin-bottom: 16px;
    left: 0;
    .ant-card-head {
      background-color: rgba(@primary-color, 0.2);
      border-left: 3px solid @primary-color;
      min-height: auto;
      .ant-card-head-wrapper {
        .ant-card-head-title {
          padding: 4px;
          color: @primary-color;
        }
      }
    }
    .ant-card-body {
      display: flex;
      flex-wrap: wrap;
      .card-logo {
        margin-right: 32px;
      }
      .card-background {
        margin-right: 32px;
      }
    }
    .input-editor {
      overflow: auto;
      width: 100%;
      height: 100%;
      div.ck.ck-content.ck-editor__editable.ck-rounded-corners {
        max-height: 500px;
      }
    }
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;