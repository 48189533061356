@import '../../../less/variable.less';

.total {
  color: @primary-color !important;
  font-weight: 600;
}
.ant-table-tbody {
  tr.ant-table-row.retail-customer {
    td.ant-table-cell {
      background: #dfe7ee;
    }
  }
}

.ant-table-cell {
  &.hide {
    display: none;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;