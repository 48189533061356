@import '../../less/variable.less';

.customer-tags {
  background-color: #d6e1ff;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 10px;

  button {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }
}

.customer-detail {
  display: block;
}

.customer-info {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: @white-color;
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;