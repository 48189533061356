@import '../../less/variable.less';

.ant-input-number {
  width: 100% !important;
}

.payment {
  &.off {
    display: none;
  }
  .line {
    font-size: @size-14;
    font-style: italic;
    color: @primary-color;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;