@import '../../../less/variable.less';

.domain-table {
  margin-top: @size-8;

  .action {
    width: 100px;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;