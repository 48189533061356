@import '../../less/variable.less';

.sidebar {
  position: relative;
  background-color: @white-color;
}
.sidebar-header {
  margin-bottom: @size-15;
  min-height: @size-48;
  .title {
    margin: 0;
  }
}

@media screen and (min-width: @breakpoint-mobile) {
  .sidebar {
    width: auto;
    padding: @size-10;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sidebar-body {
    display: none;
  }
}

@media screen and (min-width: @breakpoint-desktop-small) {
  .sidebar {
    width: @size-300;
    padding: @size-20;
    display: block;
  }

  .sidebar-body {
    display: block;
  }
  button.toggle-btn {
    display: none;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;