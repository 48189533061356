.coupon-item {
  padding: 5px;
  margin-right: 10px;
  background-color: #00cca7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
  & > p {
    margin-bottom: 0;
    color: #ffffff;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;