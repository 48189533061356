@import '../../less/variable.less';

.dealngon {
  .ant-collapse {
    width: 100%;
    border: 1px solid @border-color !important;
    border-bottom: 0 !important;
    border-radius: @size-4;
  }

  .ant-collapse-item {
    border-bottom: 1px solid @border-color !important;
  }

  .ant-collapse-header {
    padding: @size-25 !important;
    border: none;
    background-color: @border-color;
  }

  .ant-collapse-content {
    border-radius: 0 0 @size-4 @size-4 !important;
    border-top: 1px solid @border-color !important;
  }

  .category-title {
    color: @primary-color;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;