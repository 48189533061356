.drawer {
  .card-layout {
    margin-bottom: 30px;
    .ant-card-body {
      > .ant-divider {
        margin: 12px 0;
      }
      .bank-image {
        margin-top: 10px;
      }
      .ant-form-item-label {
        label {
          float: left;
        }
      }
    }
    &:first-child {
      .ant-card-body {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .cart-qr-code {
    margin-bottom: 30px;
    > .ant-card-body {
      position: relative;
      height: 200px;
    }
    .qr-code {
      .ant-skeleton .ant-skeleton-image {
        width: 150px;
        height: 150px;
      }
      width: 150px;
      height: 150px;
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;