.card-layout {
  > .ant-card-head {
    background-color: black;
    color: #ffffff;
    > .ant-card-head-wrapper {
      //   background-color: #00cca7;
    }
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;