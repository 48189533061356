@import '../../less/variable.less';

.upload-image {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 157px;
  border-radius: 16px;
  position: relative;
  & .ant-spin-nested-loading,
  & .ant-spin-container {
    height: 100%;
  }
  img {
    max-width: 100%;
    border-radius: 16px;
    height: 100%;
  }

  .reset-image {
    position: absolute;
    top: 10px;
    right: 10px;
    visibility: hidden;
    transition: all ease 0.3s;
    cursor: pointer;
  }

  &:hover .reset-image {
    visibility: visible;
  }

  .upload-button {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    padding: @size-8 0;
    z-index: 10;
    border-radius: 0 0 @size-16 @size-16;
    text-align: center;

    svg {
      cursor: pointer;
    }
  }
}

.upload-image-default {
  background-color: @border-color;
  display: flex;
  justify-content: center;
  align-items: center;

  & .upload-text {
    font-size: @size-14;
    font-weight: 700;
    color: #e3e3e3;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;