@import '../../less/variable.less';

ul.menu {
  border: none;
  .anticon,
  .ant-menu-title-content {
    color: black;
  }
  .sub-menu-item > .ant-menu-submenu-title {
    &:hover {
      * {
        color: @primary-color;
      }
    }
  }
  .sub-menu-item.active > .ant-menu-submenu-title {
    * {
      color: @primary-color;
    }
  }
  .menu-item {
    &:hover {
      * {
        color: @primary-color;
      }
    }
    &.active {
      background-color: rgba(@primary-color, 0.2);
      .ant-menu-title-content > a,
      .anticon {
        color: @primary-color;
      }
    }
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;