@import '../../../less/variable.less';

.category-group {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 8px 32px !important;
  }
  .ant-collapse-header {
    display: flex;
    align-items: center;
    padding: 0 32px;

    .ant-collapse-extra {
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    .header-primary {
      color: #1d50ff;
      font-weight: 700;
    }
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;