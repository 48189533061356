.form-edit {
  width: 100%;
  border: 1px solid #b0b1b8;
  border-radius: 4px;
  padding: 16px;
  margin: 8px 0;
  position: relative;

  .title {
    color: #00cca7;
  }
  .input-edit > div {
    width: 100px;
  }

  .delete-button {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 4px;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;