@import '../../less/variable.less';

.category-card {
  > .ant-card-body {
    background: #f5f5f7;
  }

  .ant-collapse {
    border: none;
    border-radius: 8px;
    background: #fff;
  }

  .ant-collapse > .ant-collapse-item {
    border: none;
  }
  .ant-collapse-content {
    border-radius: 0 0 8px 8px !important;
    border-top: 1px solid #f3f3f3;
  }

  > .ant-collapse-item {
    border: none;
    border-radius: 8px;
  }
  > .ant-card-body > div {
    width: 100%;
  }
  .collapse-group {
    width: 100%;

    .category-item {
      > div {
        width: 100%;
      }
    }
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;