.user-profile {
  padding: 8px;
  border-radius: 8px;
  width: 400px;
  display: flex;
  align-items: flex-start !important;

  & > .ant-space-item:last-child {
    width: 100%;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .btn {
      padding: 0 !important;
      color: #1d50ff;
      font-weight: 700;
    }
  }
}

.drawer {
  .ant-drawer-content,
  .ant-drawer-header {
    background-color: #f3f3f3 !important;
  }
}

.btn-import {
  padding: 4px 15px;
  display: inline-block;
  border: 1px solid;
  color: #ffffff;
  background: #1d50ff;
  border-color: #1d50ff;
  cursor: pointer;
}

.btn-disable {
  color: #00000040;
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.create-sale-order {
  padding: 15px;
  .title {
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 10px;
  }
}

.card {
  width: 100%;
  margin-bottom: 16px;
  left: 0;
  .ant-card-head {
    background-color: rgba(@primary-color, 0.2);
    border-left: 3px solid @primary-color;
    min-height: auto;
    .ant-card-head-wrapper {
      .ant-card-head-title {
        padding: 4px;
        color: @primary-color;
      }
    }
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;