@import '../../../../less/variable.less';

.list {
  padding: @size-15;
  width: 100%;
  .title {
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }
  .head-table {
    justify-content: flex-end;
    width: 100%;
    &.between {
      justify-content: space-between;
    }
  }
  .search-filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: @size-20;
  }

  .table {
    button.name {
      padding: 0;
    }
    .ant-table-thead > tr > th {
      font-weight: 700;
    }
    .ant-pagination-item-link,
    .ant-pagination-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .note {
    color: @red-color;
    font-weight: 700;
    font-style: italic;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;