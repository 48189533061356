@import '../../less/variable.less';

.container {
  padding: @size-15;
}

.ant-card {
  &.card-va {
    .ant-card-body {
      display: block;
    }
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;