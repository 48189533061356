@import '../../../less/variable.less';

.button {
  margin-bottom: 16px;
}

.button-add {
  background-color: @secondary-color !important;
  color: @white-color !important;
  border: none !important;
}

.title {
  margin: 16px 0 0;
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;