.content-item {
  display: flex;
  font-size: 14px;
  .title {
    margin: 0;
    width: 25%;
    margin-right: 10%;
  }
  .value {
    width: auto;
    display: flex;
    justify-content: flex-end;
    font-weight: 400;
    // color: #00cca7;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;