@import '../../less/variable.less';

.c-layout {
  flex: 1;
  .left-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .logo {
      width: 75%;
      margin: auto;
    }
    .title {
      padding-top: @size-50;
    }
    .describe {
      max-width: @percent-60;
      text-align: center;
      padding-top: @size-20;
    }
  }
  .right-layout {
    border-left: @size-1 solid @gray-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;