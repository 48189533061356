@import '../../../less/variable.less';

.profile {
  display: flex;
  flex-direction: row;

  .avatar {
    box-shadow: 0 @size-20 @size-50 -@size-20 @black-color;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: @size-30;
    .name {
      margin: 0;
    }
    .balance {
      color: @secondary-color;
    }
  }
}
.popup-info {
  margin-bottom: 20px;
  .popup-item {
    width: 80%;
  }
}

.label {
  font-size: @size-13;
  font-weight: bold;
  margin-bottom: @size-5;
  display: block;
}

.select {
  width: 100%;
}

.label ~ input {
  border-radius: @size-4;
}

div.ant-carousel .slick-dots li button {
  background: @black-color;
  opacity: 0.4;
}

div.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: @black-color;
}

@primary-color: #1D50FF;@secondary-color: #00CCA7;